$this-copyright-background-color: #404040;

.copyright__container {
  background-color: $this-copyright-background-color;
  padding: 30px  15px;
  width: 100%;
  //max-width: 2100px;
  .logo{
    max-width: 170px;
    margin-left: 20px;
    @include media-breakpoint-down(md) {
      margin-left: 5px;      
    }
    filter: brightness(0) invert(1);
    text{
      display: none;
    }
  }
}

.copyright__text {
  color: $white;
  font-size: $font-size-sm;
  margin-bottom: 0;
  padding-top: $padding-sm;
  p{
    font-size: inherit;
    margin: inherit;
  }
}
