@import "css/production.variables.scss";

.videndum-list::after,
.videndum-section .cols-container:after {
	display: block;
	clear: both;
	content: ""
}

.videndum-section * {
	font-family: Arial, Helvetica, sans-serif;
	padding: 0;
	margin: 0;
	font-size: inherit
}

.videndum-section .container {
	max-width: 1180px;
	margin: 0 auto;
	padding: 0 30px
}

.videndum-section .yellow {
	color: #fecb00
}

.videndum-section .collapsible-section {
	background: #000
}

.videndum-section {
	background-color: #535353
}

.videndum-menu {
	-webkit-column-count: 3;
	/* Chrome, Safari, Opera */
	-moz-column-count: 3;
	/* Firefox */
	column-count: 3;
}

.videndum-menu>li {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}

.videndum-section .close-videndum-menu {
	display: block;
	width: 22px;
	height: 22px;
	background: url(#{$public-path}images/vitec-brands-bar/close-white.png) no-repeat;
	position: absolute;
	right: 0;
	top: 30px;
	cursor: pointer
}

.videndum-section h2 {
	font-size: 37px;
	font-weight: 700;
	color: #fff;
	line-height: 36px
}

.videndum-section h3 {
	font-size: 22px;
	font-weight: 700;
	line-height: 30px;
	color: #fff;
	padding: 5px 0
}

ul.videndum-menu,
ul.videndum-menu li,
ul.videndum-menu li a {
	display: block
}

ul.videndum-menu ul {
	margin-bottom: 25px
}

.videndum-section h2 span {
	display: block
}

.videndum-section .yellow-separator {
	padding-left: 90px;
	border-left: 2px solid #fecb00
}

.videndum-section .white-separator {
	padding-left: 90px;
	border-left: 2px solid #ffffff
}

.videndum {
	padding: 5px 0;
	display: block
}

.videndum::after {
	content: "";
	clear: both;
	display: block;
}

.videndum .videndum-logo {
	float: left;
	line-height: 13px;
	font-size: 11px;
	font-weight: 400;
	color: #fff;
	padding-left: 40px;
	position: relative
}

.videndum .videndum-logo .logo-image {
	display: block;
	height: 47px;
	width: 150px;
	background: url(#{$public-path}images/vitec-brands-bar/videndum-logo.png) center left no-repeat;
	background-size: contain;
}

.videndum .videndum-sites {
	padding: 8px 0 7px;
	float: right;
	cursor: pointer;
	line-height: 26px;
	font-size: 13px;
	font-weight: 700;
	color: #fff;
	padding-right: 15px;
	background: url(#{$public-path}images/vitec-brands-bar/uparrow-white.png) center right no-repeat
}

.videndum .videndum-sites.active {
	background: url(#{$public-path}images/vitec-brands-bar/downarrow-white.png) center right no-repeat
}

.videndum-list {
	padding: 65px 0;
	position: relative
}

.videndum-list a {
	font-size: 14px;
	color: #fff;
	text-decoration: none;
	line-height: 18px;
	padding: 6px 0
}

.videndum-list a:hover {
	color: #fff
}

.videndum-menu.menucoltwoA,
.videndum-menu.menucoltwoB {
	display: none
}

.text-muted {
	color: #fff;
	padding-left: 90px;
	margin-top: 30px;
	display: block;
	font-size: 80%;
}

@media (min-width:1241px) {
	.videndum-section .cols-container .cols.col-large-4 {
		width: 33.33%;
		float: left
	}

	.videndum-section .cols-container .cols.col-large-2 {
		width: 16.66%;
		float: left
	}

	.videndum-section .cols-container .cols.col-large-10 {
		width: 83.33%;
		float: left
	}
}

@media (max-width:1240px) {


	.videndum-menu {
		-webkit-column-count: 2;
		/* Chrome, Safari, Opera */
		-moz-column-count: 2;
		/* Firefox */
		column-count: 2;
	}

	.videndum-section .hidden-medium {
		display: none !important
	}

	.videndum-list {
		padding: 40px 0
	}

	.videndum-section h2 {
		padding-bottom: 36px
	}

	.videndum-section .yellow-separator {
		padding-left: 30px
	}
}

@media (max-width:768px) {

	.videndum-menu {
		-webkit-column-count: 1;
		/* Chrome, Safari, Opera */
		-moz-column-count: 1;
		/* Firefox */
		column-count: 1;
	}

	.videndum-section .cols {
		display: block;
		float: none !important;
		width: auto !important
	}

	.videndum-section .yellow-separator {
		padding-left: 0;
		border: none
	}

	.videndum-list {
		padding: 30px 0 0
	}

	.videndum-section h2 {
		font-size: 30px;
		line-height: 30px;
		padding-bottom: 16px;
		padding-left: 20px
	}

	.videndum-section .container {
		padding: 0 20px
	}

	.videndum-section .collapsible-section .container {
		padding: 0
	}

	ul.videndum-menu ul {
		margin: 0
	}

	.videndum-section .close-videndum-menu {
		right: 20px
	}

	.videndum-list .videndum-menu a,
	.videndum-section h3 {
		padding: 20px 15px;
		line-height: 20px
	}

	.videndum-section h3 {
		font-size: 15px;
		letter-spacing: .1em;
		text-transform: uppercase;
		cursor: pointer;
		border-bottom: 1px solid #333;
		background: url(#{$public-path}images/vitec-brands-bar/downarrow.png) right 20px center no-repeat;
		padding-right: 35px
	}

	.videndum-list .videndum-menu a {
		background-color: rgba(30, 30, 30, 0);
		transition: all ease-in-out .4s
	}

	.videndum-list .videndum-menu a:hover {
		background-color: rgba(30, 30, 30, 1)
	}

	.videndum-section h3.active {
		background-image: url(#{$public-path}images/vitec-brands-bar/uparrow.png)
	}

	ul.videndum-menu>li>ul {
		border-bottom: 1px solid #333;
		background-color: #191919;
		display: none
	}
}