:root {
    --cb-text-color: #fff;
    --cb-overlay-background: rgb(0, 0, 0, 0.5);
    --cb-button-border: 1px solid #fff;
    --cb-button-color: #fff;
    --cb-dialog-max-width: 1600px;
    --cb-dialog-max-width: 100%;
    --cb-button-border-radius: 1px;
    --cb-button-font-family: inherit;
    --cb-button-font-size: 18px;
    --cb-button-background: #000;
    --cb-description-font-size-desktop: 16px;
    --cb-primary: #000;
    --cb-dark: #222;
    --cb-background: #000;

}
    body.brand-GTZ{
        /* same as default "vid basic style"*/
    }
    body.brand-MNF{
        --cb-text-color: #000;
        --cb-overlay-background: rgb(255, 255, 255, 0.5);
        --cb-button-border: 1px solid #e42d24;
        --cb-button-background: #e42d24;
        --cb-button-color: #fff;
        --cb-primary: #e42d24;
        --cb-dark: #f0f0f0;
        --cb-button-color: #fff;
        --cb-background: #fff;
    }
    body.brand-LWP{
        --cb-text-color: #fff;
        --cb-overlay-background: rgb(0, 0, 0, 0.5);
        --cb-button-border: 1px solid #faa61b;
        --cb-button-background: #faa61b;
        --cb-button-color: #fff;
        --cb-primary: #faa61b;
        --cb-dark: #222;
        --cb-background: #000;
    }
    body.brand-JBY{
        --cb-text-color: #000;
        --cb-overlay-background: rgb(255, 255, 255, 0.5);
        --cb-button-border: 1px solid #2b24c5;
        --cb-button-background: #2b24c5;
        --cb-button-color: #fff;
        --cb-primary: #2b24c5;
        --cb-dark: #f0f0f0;
        --cb-button-color: #fff;
        --cb-background: #fff;
    }
    body.brand-SPY{
        --cb-text-color: #fff;
        --cb-overlay-background: rgb(0, 0, 0, 0.5);
        --cb-button-border: 1px solid #26bf73;
        --cb-button-background: #26bf73;
        --cb-button-color: #fff;
        --cb-primary: #26bf73;
        --cb-dark: #222;
        --cb-background: #000;
    }
    body.brand-SAV{
        --cb-text-color: #000;
        --cb-overlay-background: rgb(255, 255, 255, 0.5);
        --cb-button-border: 1px solid #0054a4;
        --cb-button-background: #0054a4;
        --cb-button-color: #fff;
        --cb-primary: #0054a4;
        --cb-dark: #f0f0f0;
        --cb-button-color: #fff;
        --cb-background: #fff;
    }

#CookieBanner {
    position: fixed;
    z-index: 2147483645;
    min-height: 100vh;
    min-width: 100vw;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    pointer-events: none;
    display: none;
}

#CookieBanner.is-visible-cookie-banner {
    display: flex;
    align-items: flex-start;
    justify-content: center
}

#CookieBanner,
#CookieBanner * {
    box-sizing: border-box;
    text-underline-offset: .125em;
    outline-offset: 3px
}

#CookieBannerOverlay {
    background: rgba(0, 0, 0, .4);
    background: var(--cb-overlay-background, rgba(0, 0, 0, .4));
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: auto;
    z-index: 10
}

.is-visible-cookie-banner #CookieBannerOverlay {
    animation: cookieBannerFadeIn .25s ease-in-out;
    animation-fill-mode: forwards
}

.is-closing-cookie-banner #CookieBannerOverlay {
    animation: cookieBannerFadeOut .25s ease-in-out;
    animation-fill-mode: forwards;
    pointer-events: none
}

@media(prefers-reduced-motion:reduce) {
    #CookieBanner * {
        animation-duration: .001ms !important;
        transition-duration: .001ms !important
    }
}

#CookieBannerNotice {
    color: #fff;
    padding: 40px 28px;
    overflow: auto;
    max-height: 100vh;
    max-height: calc(100vh - 24px);
    max-height: calc(100vh - 24px - env(safe-area-inset-bottom, 0));
    width: 100%;
    width: 100%;
    max-width: 100%;
    background: var(--cb-background, #000);
    color: var(--cb-text-color, #fff);
    margin: 0;
    z-index: 500;
    pointer-events: auto
}

@media(min-width:450px) {
    #CookieBannerNotice {
        max-height: calc(100vh - 48px);
        max-height: calc(100vh - 48px - env(safe-area-inset-bottom, 0));
        width: 100%
    }
}

@media(max-width:800px) {
    #CookieBannerNotice {
        padding: 24px;
        position: absolute;
        bottom: 0
    }
}

.is-visible-cookie-banner #CookieBannerNotice {
    animation: cookieBannerSlideIn .25s ease-in-out;
    animation-fill-mode: forwards
}

.is-closing-cookie-banner #CookieBannerNotice {
    animation: cookieBannerSlideOut .25s ease-in-out;
    animation-fill-mode: forwards;
    pointer-events: none
}

@media(min-width:800px) {
    #CookieBannerNotice {
        padding: 48px
    }
}

@media(min-width:1000px) {
    #CookieBannerNotice {
        padding: 30px
    }
}

@keyframes cookieBannerFadeIn {
    0 {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes cookieBannerFadeOut {
    0 {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes cookieBannerSlideIn {
    0 {
        opacity: 0;
        transform: translateY(96px)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes cookieBannerSlideOut {
    0 {
        opacity: 1;
        transform: translateY(0)
    }

    100% {
        opacity: 0;
        transform: translateY(96px)
    }
}

#CookieBanner button {
    background: 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    box-shadow: none
}

#CookieBanner button>* {
    pointer-events: none
}

#CookieBanner ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
    text-indent: 0
}

#CookieBanner .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important
}

#CookieBanner .cookiebanner__main {
    max-width: 1400px;
    margin: auto
}

#CookieBanner .cookiebanner__main__inner {
    position: relative;
    max-width: 1080px;
    max-width: var(--cb-dialog-max-width, 1080px);
    margin: 0 auto;
    display: flex;
    flex-direction: column
}

#CookieBanner #CookieBannerHide {
    position: absolute;
    right: 0;
    color: var(--cb-text-color);
    font-size: 13px
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__main__content {
        margin-right: 48px
    }
}

#CookieBanner .cookiebanner__main__title {
    font-weight: bold;
    font-size: 20px;
    font-family: var(--cb-title-font-family);
    font-weight: var(--cb-title-font-weight, bold);
    font-size: var(--cb-title-font-size-mobile, 16px);
    margin-top: 0;
    margin-bottom: 10px;
    text-align: left
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__main__title {
        font-size: 28px;
        font-size: var(--cb-title-font-size-desktop, 28px);
        margin-bottom: 20px
    }
}

#CookieBanner .cookiebanner__main__description {
    font-size: 15px;
    font-family: var(--cb-description-font-family);
    font-weight: var(--cb-description-font-weight);
    font-size: var(--cb-description-font-size-mobile, 13px);
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__main__description {
        font-size: 16px;
        font-size: var(--cb-description-font-size-desktop, 16px);
        line-height: 1.75
    }
}

#CookieBanner .cookiebanner__buttons {
    flex-shrink: 0;
    margin-top: 20px
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__buttons {
        margin-top: 10px
    }
}

#CookieBanner .cookiebanner__buttons ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap
}

#CookieBanner .cookiebanner__buttons li {
    margin-bottom: 12px;
    margin-left: 8px;
    margin-right: 8px;
    width: 100%
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__buttons li {
        width: auto
    }
}

#CookieBanner .cookiebanner__buttons__details {
    font-size: 16px;
    font-size: var(--cb-details-button-font-size, 14px);
    margin-top: -0.5em;
    padding: 0 2px;
    width: 100%;
    text-decoration: underline;
    box-shadow: none;
    text-transform: none;
    color: var(--cb-text-color)
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__buttons__details {
        font-size: var(--cb-details-button-font-size, 16px);
        padding: 5px 15px
    }
}

#CookieBanner .cookiebanner__buttons__details:hover,
#CookieBanner .cookiebanner__buttons__details:focus,
#CookieBanner .cookiebanner__buttons__details:active {
    text-decoration: underline
}

#CookieBanner .cookiebanner__buttons__accept,
#CookieBanner .cookiebanner__buttons__deny {
    background: transparent;
    border-radius: 21px;
    font-weight: bold;
    font-size: 16px;
    border: 0;
    transition: .2s ease-in-out;
    display: block;
    padding: 10px;
    background: var(--cb-button-background, transparent);
    border-radius: var(--cb-button-border-radius, 3px);
    font-weight: var(--cb-button-font-weight, bold);
    font-size: var(--cb-button-font-size, 14px);
    font-family: var(--cb-button-font-family);
    border: var(--cb-button-border, 1px solid var(--cb-primary));
    color: var(--cb-button-color, #fff);
    text-align: center;
    width: 100%
}

@media(min-width:800px) {

    #CookieBanner .cookiebanner__buttons__accept,
    #CookieBanner .cookiebanner__buttons__accept-selection,
    #CookieBanner .cookiebanner__buttons__deny {
        font-size: var(--cb-button-font-size, 16px);
        padding: 13px 24px
    }
}

#CookieBanner .cookiebanner__buttons__accept {
    color: #fff;
    background: var(--cb-primary);
    border: var(--cb-button-border, 1px solid var(--cb-primary))
}

#CookieBanner .cookiebanner__buttons__accept:hover,
#CookieBanner .cookiebanner__buttons__accept:focus,
#CookieBanner .cookiebanner__buttons__accept:active {
    color: #fff;
    background: var(--cb-primary);
    color: var(--cb-accept-button-active-color, var(--cb-button-active-color, #fff));
    background: var(--cb-accept-button-active-background, var(--cb-button-active-background, var(--cb-primary)));
    border: var(--cb-accept-button-active-border, var(--cb-accept-button-border, var(--cb-button-active-border, var(--cb-button-border, 1px solid var(--cb-primary)))))
}

#CookieBanner .cookiebanner__buttons__deny,
#CookieBanner .cookiebanner__buttons__deny:hover,
#CookieBanner .cookiebanner__buttons__deny:focus,
#CookieBanner .cookiebanner__buttons__deny:active {
    color: #fff;
    background: var(--cb-primary);
    color: var(--cb-button-active-color, #fff);
    background: var(--cb-button-active-background, var(--cb-primary));
    border: var(--cb-button-active-border, var(--cb-button-border, 1px solid var(--cb-primary)))
}

#CookieBanner .cookiebanner__details {
    margin-top: 24px;
    display: none;
    max-width: 1400px;
    margin: auto
}

#CookieBanner.is-details-open .cookiebanner__details {
    display: block
}

#CookieBanner .cookiebanner__details__inner {
    max-width: 1080px;
    max-width: var(--cb-dialog-max-width, 1080px);
    margin: 0 auto;
    flex: 1 720px
}

#CookieBanner .cookiebanner__details__preferences {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 10px;
    flex: 100%
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__details__preferences {
        margin-top: 16px;
        margin-bottom: 0
    }
}

#CookieBanner .cookiebanner__details__preferences {
    flex: 1 1 50%
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__details__preferences {
        margin-top: 0;
        flex: 0 1
    }
}

#CookieBanner .cookiebanner__details__preferences__options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
}

#CookieBanner .cookiebanner__preference__heading {
    display: flex;
    align-items: center
}

#CookieBanner .cookiebanner__preference {
    background: var(--cb-dark);
    padding: 16px;
    width: 100%;
    margin-bottom: 12px
}

@media(min-width:540px) {
    #CookieBanner .cookiebanner__preference {
        margin-bottom: 16px
    }
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__preference {
        padding: 32px
    }
}

#CookieBanner .cookiebanner__preference::last-of-type {
    margin-bottom: 0
}

#CookieBanner .cookiebanner__preference input {
    position: relative;
    opacity: 1;
    width: auto;
    height: auto
}

#CookieBanner .cookiebanner__preference__label {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-direction: column
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__preference__label {
        flex-direction: row
    }

    #CookieBanner .cookiebanner__preference input {
        margin-bottom: .6em
    }
}

#CookieBanner .cookiebanner__preference input:focus+.cookiebanner__preference__label,
#CookieBanner .cookiebanner__preference__label:hover {
    text-decoration: underline;
    cursor: pointer
}

#CookieBanner .cookiebanner__preference__ui {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: 80px;
    height: 44px;
    background: #868e96;
    border-radius: 40px;
    transition: all .2s ease-in-out;
    cursor: pointer;
    display: none
}

#CookieBanner .cookiebanner__preference__text {
    display: block;
    margin-top: 10px
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__preference__text {
        margin-left: 16px;
        margin-top: 0
    }
}

#CookieBanner .cookiebanner__preference__title {
    display: block;
    margin: 0;
    font-size: 14px
}

@media(min-width:800px) {
    #CookieBanner .cookiebanner__preference__title {
        font-size: 16px
    }
}

#CookieBanner .cookiebanner__preference__description {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px
}

#CookieBanner .cookiebanner__preference__toggle {
    flex-shrink: 0;
    margin-left: 32px;
    text-decoration: underline
}

@media(max-width:539px) {
    #CookieBanner .cookiebanner__preference__toggle {
        flex-shrink: 1;
        display: block;
        text-align: center;
        width: 100%;
        margin-top: 16px;
        margin-left: 0
    }
}

#CookieBanner .cookiebanner__preference__toggle:hover,
#CookieBanner .cookiebanner__preference__toggle:focus,
#CookieBanner .cookiebanner__preference__toggle:active {
    text-decoration: underline
}

#CookieBanner .cookiebanner__preference__more {
    display: none;
    margin-top: 24px
}

#CookieBanner .cookiebanner__preference__more__description {
    margin: 0;
    font-size: 16px
}

#CookieBanner .cookiebanner__preference__more__list {
    margin-top: 16px;
    width: 100%;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, .25);
    background-repeat: no-repeat;
    background-color: var(--cb-dark);
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-position: 0 0, 100%, 0 0, 100%;
    background-attachment: local, local, scroll, scroll
}

#CookieBanner .cookiebanner__preference__more__list table {
    width: 100%
}

#CookieBanner .cookiebanner__preference__more__list tbody td {
    background: transparent;
    border-top: 1px solid rgba(0, 0, 0, .25)
}

#CookieBanner .cookiebanner__preference__more__list tbody .CybotCookiebotDialogDetailBodyContentCookieTypeTableEmpty {
    border-top: 0
}

@media(max-width:800px) {

    table.CybotCookiebotDialogDetailBodyContentCookieTypeTable thead th,
    table.CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td {
        display: table-row
    }

    table.CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody tr td:first-child {
        font-weight: bold;
        line-height: 5em;
        border-top: 5px solid var(--cb-text-color) !important;
    }
}

#CookieBanner .cookiebanner__preference__more__list a {
    text-decoration: underline;
    color: inherit
}

#CookieBanner .cookiebanner__preference__more__list th,
#CookieBanner .cookiebanner__preference__more__list td {
    vertical-align: baseline;
    min-width: 176px;
    text-align: left;
    padding: 6px;
    background: transparent;
    font-size: 16px;
    word-break: break-word
}

@media(min-width:800px) {

    #CookieBanner .cookiebanner__preference__more__list th,
    #CookieBanner .cookiebanner__preference__more__list td {
        padding: 8px
    }
}

#CookieBanner.is-visible-cookie-banner.is-details-open .cookiebanner__preference__more.is-open {
    display: block;
    max-height: 200px;
    overflow: auto;
}

#CookieBanner .cookiebanner__preference__ui__handle {
    display: flex;
    margin-left: 8px;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    transition: all .2s ease-in-out;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2)
}

#CookieBanner .cookiebanner__preference input:checked+label .cookiebanner__preference__ui {
    background: #16a085;
    background: var(--cb-preference-accent-color, #16a085)
}

#CookieBanner .cookiebanner__preference input:checked+label .cookiebanner__preference__ui__handle {
    margin-left: 50%;
    background: #fff
}

#CookieBanner .cookiebanner__details__preferences__buttons {
    text-align: center;
    margin-top: 8px
}


@media(min-width:800px) {
    #CookieBanner .cookiebanner__accept-selection {
        font-size: var(--cb-button-font-size, 15px);
        padding: 13px 24px
    }
}

#CookieBanner .cookiebanner__accept-selection:hover,
#CookieBanner .cookiebanner__accept-selection:focus,
#CookieBanner .cookiebanner__accept-selection:active {
    color: #fff;
    background: var(--cb-primary);
    color: var(--cb-button-active-color, #fff);
    background: var(--cb-button-active-background, var(--cb-primary));
    border: var(--cb-button-active-border, var(--cb-button-border, 1px solid var(--cb-primary)))
}

#CookieBanner .cookiebanner__details__updated {
    margin-top: 24px;
    text-align: center
}

#CookieBanner .cookiebanner__details__updated p {
    font-size: 15px
}

#CookieBanner.is-visible-cookie-banner:not(.is-details-open) #CookieBannerDetails {
    display: flex;
    width: 100%;
    margin: 10px auto 0 auto
}

@media(min-width:800px) {
    #CookieBanner.is-visible-cookie-banner:not(.is-details-open) #CookieBannerDetails {
        margin: 20px auto 0 auto
    }
}

#CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__details__preferences__options {
    display: flex;
    flex-wrap: wrap
}

@media(min-width:800px) {
    #CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__details__preferences__options {
        display: flex;
        flex-wrap: nowrap;
        flex: 0 1
    }
}

#CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__preference__toggle {
    display: none
}

#CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__preference {
    background: transparent;
    margin: 0;
    flex: 0 1 50%;
    padding: 0
}

@media(min-width:800px) {
    #CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__preference {
        flex: 0 1;
        padding: 10px
    }
}

#CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__preference__ui {
    width: 40px;
    height: 22px
}

#CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__preference__ui__handle {
    width: 18px;
    height: 18px;
    margin-left: 4px
}

#CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__details__updated {
    display: none
}

#CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__buttons__details {
    width: auto
}

#CookieBanner.is-visible-cookie-banner.is-details-open .cookiebanner__buttons__details:before {
    content: '\f106';
    font-family: 'Font Awesome 5 Pro';
    display: inline-block;
    padding-right: 15px
}

@media(min-width:800px) {
    #CookieBanner.is-visible-cookie-banner:not(.is-details-open) .cookiebanner__details__inner {
        display: flex
    }
}

#CookieBanner a {
    color: white;
    text-decoration: underline !important
}

body div#iubenda-cs-banner {
    display: none !important;
}

/* cookie "declaration" consent css */

.CookieDeclaration {
    max-width: 1200px;
    margin: auto;
    padding: 100px 20px;
}

.CookieDeclaration .CookieDeclarationDialogText {
    font-weight: bold;
    font-size: 1.33em;
}

.CookieDeclaration a {
    color: var(--cb-primary);
}

.CookieDeclaration #CookieDeclarationUserStatusPanel {
    display: block;
    background-color: var(--cb-dark);
    color: var(--cb-text-color);
    padding: 1.25em 1.5em;
    border: var(--cb-button-border, 1px solid var(--cb-primary));
}

.CookieDeclaration #CookieDeclarationUserStatusPanel a {
    color: var(--cb-button-color);
    background-color: var(--cb-primary);
    border: var(--cb-button-border, 1px solid var(--cb-primary));
    padding: 0.75em 1em;
    text-decoration: none;
    margin: 1em 0px;
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
}