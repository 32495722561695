//  Sprite
//
//  Markup:
//
// Style guide: lowepro.blocks.sprite
@mixin sprite ($label, $proportion, $bgsize, $position){
    content: $label;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: $proportion;
    background-image: url(#{$public-path}/images/sprite.svg);
    background-position: $position;
    background-size: $bgsize;
    background-repeat: no-repeat;
    text-indent: -999px;
    overflow: hidden;
    image-rendering: auto;            
    -ms-interpolation-mode: bicubic;

    @media all and (-ms-high-contrast:none)
    {
        background-size: $bgsize*1.01;
    }

}
/* logo white */
.logo-lwp-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Lowepro', 62.5%, 720%, 22.5% 51%);
    }
    img{
        display: none;
    }
}
.logo-mnf-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Manfrotto', 62.5%, 720%, 22.5% -0.9%);
    }
    img{
        display: none;
    }
}
.logo-jby-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Joby', 62.5%, 720%, 22.5% 59.5%);
    }
    img{
        display: none;
    }
}
.logo-gtz-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Gitzo', 62.5%, 720%, 22.5% 8%);
    }
    img{
        display: none;
    }
}
.logo-ng-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('National Geographic', 62.5%, 720%, 22.5% 17.1%);
    }
    img{
        display: none;
    }
}
.logo-ave-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Avenger', 62.5%, 720%, 22.5% 43%);
    }
    img{
        display: none;
    }
}
.logo-col-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Colorama', 62.5%, 720%, 22.5% 33.8%);
    }
    img{
        display: none;
    }
} 
.logo-bsy-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Syrp', 62.5%, 720%, 22.5% 67%);
    }
    img{
        display: none;
    }
}
.logo-ryc-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Rycote', 62.5%, 720%, 22.5% 74%);
    }
    img{
        display: none;
    }
}
.logo-sav-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Savage', 62.5%, 720%, 22.5% 82.8%);
    }
    img{
        display: none;
    }
}
.logo-aud-lg-w{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Audix', 62.5%, 720%, 22.5% 90.6%);
    }
    img{
        display: none;
    }
}

/* logo black */
.logo-lwp-lg-k{
    &.logo{
        min-width: 150px;
        aspan{
            margin-top:-12px;
            margin-bottom:-12px;
        }
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Lowepro', 62.5%, 720%, 39% 51%);
    }
    img{
        display: none;
    }
}
.logo-mnf-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Manfrotto', 62.5%, 720%, 39% -0.9%);
    }
    img{
        display: none;
    }
}
.logo-jby-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Joby', 62.5%, 720%, 39% 59.4%);
    }
    img{
        display: none;
    }
}
.logo-gtz-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Gitzo', 62.5%, 720%, 39% 8%);
    }
    img{
        display: none;
    }
}
.logo-ng-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('National Geographic', 62.5%, 720%, 39% 16.54%);
    }
    img{
        display: none;
    }
}
.logo-ave-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Avenger', 62.5%, 720%, 39% 42.6%);
    }
    img{
        display: none;
    }
}
.logo-col-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Colorama', 62.5%, 720%, 39% 33.8%);
    }
    img{
        display: none;
    }
} 
.logo-bsy-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Syrp', 62.5%, 720%, 39% 67.2%);
    }
    img{
        display: none;
    }
} 
.logo-ryc-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Rycote', 62.5%, 720%, 39% 74%);
    }
    img{
        display: none;
    }
}
.logo-sav-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Savage', 62.5%, 720%, 39% 82.8%);
    }
    img{
        display: none;
    }
}
.logo-aud-lg-k{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Audix', 62.5%, 720%, 39% 90.6%);
    }
    img{
        display: none;
    }
}


/* logo color */
.logo-lwp-lg-c{
    &.logo{
        min-width: 150px;
        aspan{
            margin-top:-12px;
            margin-bottom:-12px;
        }
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Lowepro', 62.5%, 720%, 6% 51%);
    }
    img{
        display: none;
    }
}
.logo-mnf-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Manfrotto', 62.5%, 720%, 6% -0.9%);
    }
    img{
        display: none;
    }
}
.logo-jby-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Joby', 62.5%, 720%, 6% 59.4%);
    }
    img{
        display: none;
    }
}
.logo-gtz-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Gitzo', 62.5%, 720%, 6% 8%);
    }
    img{
        display: none;
    }
}
.logo-ng-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('National Geographic', 62.5%, 720%, 6% 16.54%);
    }
    img{
        display: none;
    }
}
.logo-ave-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Avenger', 62.5%, 720%, 6% 42.6%);
    }
    img{
        display: none;
    }
}
.logo-col-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Colorama', 62.5%, 720%, 6% 33.8%);
    }
    img{
        display: none;
    }
}
.logo-bsy-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Syrp', 62.5%, 720%, 6% 67%);
    }
    img{
        display: none;
    }
}
.logo-ryc-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Rycote', 62.5%, 720%, 6% 74%);
    }
    img{
        display: none;
    }
}
.logo-vis-lg-c {
    &.logo {
        min-width: 150px;
    }

    & > a span {
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Videndum Media Solutions', 62.5%, 720%, 56.9% 1%);
    }

    img {
        display: none;
    }
}
.logo-sav-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Savage', 62.5%, 720%, 5.9% 81.8%);
    }
    img{
        display: none;
    }
}
.logo-aud-lg-c{
    &.logo{
        min-width: 150px;
    }
    & > a span{
        /* sprite ($label, $proportion, $bgsize, $position) */
        @include sprite ('Audix', 62.5%, 720%, 5.9% 89.8%);
    }
    img{
        display: none;
    }
}
/* footer brands */
.footer-brands{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    max-width: 620px;
    li{
        display: inline-block;
        width: 25%;
        padding: 3%;
        margin: -3% 0px -3% -4px;
        &span{
            max-width: 125px;
            min-width: 80px;    
        }
    }
}
/* cc */
.cc-list{
    margin: 0;
    padding: 0;
    li{
        max-width: 59px;
        width: 100%;
        margin-right: 10px;
        display: inline-block;
        overflow: hidden;
        border-radius: 3px;
    }
    img{
        display: none;
    }
}
.cc-visa > span{
    /* sprite ($label, $proportion, $bgsize, $position) */
    @include sprite ('Credit Card Visa', 61%, 2490%, 0% 0%);
}
.cc-visa-e > span{
    @include sprite ('Credit Card Visa Electron', 61%, 2490%, 0% 22.78%);
}
.cc-mastercard > span{
    @include sprite ('Credit Card Mastercard', 61%, 2490%, 0% 2.5%);
}
.cc-maestro > span{
    @include sprite ('Credit Card Maestro', 61%, 2490%, 0% 17.72%);
}
.cc-paypal > span{
    @include sprite ('Pay Pal', 61%, 2490%, 0% 20.246010785%);
}
.cc-paypal-credit > span{
    /* sprite ($label, $proportion, $bgsize, $position) */
    @include sprite ('PayPal Credit', 61%, 2490%, 0% 20.246010785%);
}
.cc-westurn-union > span{
    @include sprite ('Credit Card Western Union', 61%, 2490%, 0% 25.3%);
}
.cc-american-express > span{
    @include sprite ('American Express', 61%, 2490%, 0% 7.6%);
}
.cc-cirrus > span{
    @include sprite ('Credit Card Cirrus', 61%, 2490%, 0% 10.075);
}
.cc-jcb > span{
    @include sprite ('Credit Card JCB', 61%, 2490%, 0% 15.193%);
}
.cc-discover > span{
    @include sprite ('Credit Card Discover', 61%, 2490%, 0% 12.65%);
}
.cc-world-pay > span{
    @include sprite ('Credit Card World Pay', 61%, 2490%, 0% 5.025%);
}
.cc-diner > span{
    @include sprite ('Credit Card Diner', 61%, 2490%, 0% 27.75%);
}


/* country flags */
.country-selector .dropdown-item.active{
    background-color: $gray-200;
    font-weight: $font-weight-bold;
    color: $black;
}
[data-country]{
    padding-left: 40px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 10px center;
}
[data-country="XX"] {    background-image: url(#{$public-path}/images/flags/global.svg);}
[data-country="US"] {    background-image: url(#{$public-path}/images/flags/us.svg);}
[data-country="CA"] {    background-image: url(#{$public-path}/images/flags/ca.svg);}
[data-country="BE"] {    background-image: url(#{$public-path}/images/flags/be.svg);}
[data-country="FR"] {    background-image: url(#{$public-path}/images/flags/fr.svg);}
[data-country="DE"] {    background-image: url(#{$public-path}/images/flags/de.svg);}
[data-country="IE"] {    background-image: url(#{$public-path}/images/flags/ie.svg);}
[data-country="IT"] {    background-image: url(#{$public-path}/images/flags/it.svg);}
[data-country="NL"] {    background-image: url(#{$public-path}/images/flags/nl.svg);}
[data-country="GB"] {    background-image: url(#{$public-path}/images/flags/gb.svg);}
[data-country="JP"] {    background-image: url(#{$public-path}/images/flags/jp.svg);}
[data-country="CN"] {    background-image: url(#{$public-path}/images/flags/cn.svg);}
[data-country="HK"] {    background-image: url(#{$public-path}/images/flags/hk.svg);}
[data-country="AU"] {    background-image: url(#{$public-path}/images/flags/au.svg);}
[data-country="SE"] {    background-image: url(#{$public-path}/images/flags/se.svg);}
[data-country="ES"] {    background-image: url(#{$public-path}/images/flags/es.svg);}
[data-country="NO"] {    background-image: url(#{$public-path}/images/flags/no.svg);}
[data-country="IN"] {    background-image: url(#{$public-path}/images/flags/in.svg);}
[data-country="RU"] {    background-image: url(#{$public-path}/images/flags/ru.svg);}
[data-country="PL"] {    background-image: url(#{$public-path}/images/flags/pl.svg);}
[data-country="HU"] {    background-image: url(#{$public-path}/images/flags/hu.svg);}
[data-country="MX"] {    background-image: url(#{$public-path}/images/flags/mx.svg);}
[data-country="KR"] {    background-image: url(#{$public-path}/images/flags/kr.svg);}
[data-country="CH"] {    background-image: url(#{$public-path}/images/flags/ch.svg);}

/*-- general loading --*/
.loading__ico {
    display: block;
    pointer-events: none;
    position: fixed;
    z-index: 9;
    background-color: rgba(255,255,255,0.35);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    > img{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        margin-top: -20px;
        margin-left: -20px;
    }
}

/* checkbox brand */

input[name="signUpBrandOfInterest"] + label.form-check-label{
    display: block;
    text-indent: -999px;
    overflow: hidden;
    min-width:90px;
    height: 50px;
    &[for="Manfrotto"]{
        @include sprite ('Manfrotto', 62.5%, 720%, 6% -0.9%);
    }
    &[for="JOBY"]{
        @include sprite ('Joby', 62.5%, 720%, 6% 59.4%);
    }
    &[for="Lowepro"]{
        @include sprite ('Lowepro', 62.5%, 720%, 6% 51%);
    }
    &[for="Gitzo"]{
        @include sprite ('Gitzo', 62.5%, 720%, 6% 8%);
    }
  }
  