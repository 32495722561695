@keyframes nav-slide-down {
  from {transform: translateY(-100px);}
  to {transform: translateY(0px);}
}
.main-header {
  z-index: 15;
  position: relative;
  top: 0px;
  //max-width: 2100px;

  &.main-sticky {
    &:after {
      content: '';
      display: block;
      height: 89px;
    }

    >.main-nav {
      position: fixed;
      top: 0;
      width: 100%;
      animation-name: nav-slide-down;
      animation-duration: 300ms;
      .main-menu__text {
        padding: 8px 0 8px 8px;
      }

      @include media-breakpoint-up(lg) {
        .logo {
          width: 80px;
          margin-right: 20px;
        }
      }
    }
  }
}