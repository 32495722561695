.top-bar {
    position: relative;
    background-color: $gray-200;
    min-height: 40px;

    .frame-default {
        //Override styles TYPO3
        width: 100%;
        margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
        position: static;
        margin-top: -50px;

        .country-selector {
            position: fixed;
            z-index: 99999;
            right: 45px;
            top: 33px;
        }
    }

    .position-absolute {
        top: 0;
        right: 0;
        background-color: $gray-200;
    }
}
