footer{
  margin-top: -16px;
  //max-width: 2100px;
}
.footer-menu__container {
  padding: 30px 0 50px 0;
  background-color: $black;
  @include make-container();
  margin-left: 0px;
  width: 100%;
  //max-width: 2100px;
  @include media-breakpoint-down(md) {
    padding: 25px 0 50px 0;
  }
  .frame-type-html > div.h4{
    text-transform: lowercase;
    font-weight: $font-weight-bold;
  }
  ul{
    padding: 0px;
  }
}

.footer-menu  {
  color: $white;

  header {

    h4 {
      line-height: 1;
      margin-bottom: 0;
    }
  }
  li {
    list-style: none;
    line-height: $line-height-xs;
    filter: brightness(0) invert(1);
  }

  a, p {
    font-size: 13px;
    line-height: 1.615;
    //override bootstrap styles
    list-style: none;
    color: $white;
    //override bootstrap styles

    &:hover {
      text-decoration: none;
    }
  }
  @include media-breakpoint-down(sm) {
    .frame-type-html > div.h4{
      font-size: 18px;
    }
    .frame-type-menu_subpages{
      position: relative;
      padding: 15px;
      margin: 0 -15px;
      border-bottom: 1px solid $gray-800;
      header h4{
        font-size: 18px;
      }
      ul {
        padding: 0px;
        overflow: hidden;
        max-height: 0px;
        transition: max-height ease 200ms;
        li{
          &:first-child(){
            padding-top: 15px;
          }
          a{
            font-size: 14px;
            line-height: 30px;
          }
        } 
      }
      &.active{
        ul{
          max-height: 999px;
        }
      }
    }
  }
}
