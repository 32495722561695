$this-brand-background-color: #333333;
.brand-menu-box{
  overflow: hidden;
}

.brand-menu__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: $this-brand-background-color;
  @include make-container();
  margin-left: 0px;
  position: relative;
  z-index: 99999;

  @include media-breakpoint-down(md) {
    position: relative;
    z-index: 999;
    max-width: 100%;
  }
  i {
    color: $white;
  }
}

.brand-menu__trigger {
  color: $white;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: color ease 200ms;
  i{
    margin-right: $padding-sm;
    transform: rotate(0deg);  
    transition: transform ease 200ms;
  }
  &:hover{
    i{
      transform: rotate(180deg);
    }
    color: $primary;
  }
}
/* endorsement logo styling*/
.vitec-logo{
  width: 100%;
  max-width: 165px;
  img{
    margin:-10px;
  }
}