@import "bootstrap/scss/_functions.scss";
@import "../main/bootstrap_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@import "../main/elements/_autosuggest.scss";

#regw {
    padding-top: 8vh;
    /* overrride standard separator */
    hr {
        border-top-color: rgba($black, 0.35);
    }
    .logo-over-img{
        position: absolute;
        left: 15px;
        top: 10px;
        width: 100px;
        display: block;
    }
}

.regw {
    &__blockyears {
        padding: $padding-lg*2 0;
        margin: 0 -$padding-xxxxs;

        > .col {
            padding: $padding-xxxxs;

            @include media-breakpoint-down(md) {
                display: none;
            }

            button {
                width: 100%;
                display: block;
                padding: $padding-sm $padding-xxs;
                background-color: $white;
                border: 1px solid $gray-500;
                border-radius: $border-radius;
                color: $gray-500;
                font-weight: $font-weight-bold;
                line-height: normal;
                text-transform: uppercase;
                pointer-events: none;


                strong {
                    span {
                        font-size: $font-size-xl*2;
                        line-height: $font-size-xl*2;
                        display: block;

                        @include media-breakpoint-down(xl) {
                            font-size: $font-size-xl*1.2;
                            line-height: $font-size-xl*1.2;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: $font-size-xl;
                            line-height: $font-size-xl;
                        }

                        font-weight: $font-weight-bold;
                    }
                }
            }

            &.active {
                display: block;

                button {
                    display: block;
                    background-color: $green;
                    border-color: $green;
                    color: $white;
                }
            }
        }
    }

    &__form {
        font-size: 0.85rem;

        .loading__ico {
            display: none;
            position: absolute;

            &--show {
                display: block;
                position: fixed;
            }
        }

        &.loading .loading__ico {
            display: block;
            position: fixed;
        }

        &__input {
            position: relative;
            display: block;

            > img {
                position: absolute;
                z-index: 4;
                top: 0;
                left: 0;
                max-width: 30px;
                margin-left: 30px;
                height: 100%;
            }

            select {
                width: 100%;
                min-height: 40px;
                height: 7.75vh;
                max-height: 100px;
                border: none;
                border-bottom: 1px solid $gray-500;
                border-radius: $border-radius-sm;
                background-color: $gray-200;
                font-size: $font-size-xl;
                padding-left: 90px;
                outline-color: $gray-500;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;

                @include media-breakpoint-down(xl) {
                    font-size: $font-size-lg;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $font-size-lg;
                }

                option {
                    font-size: $font-size-base;
                }
                /* For IE10 */
                &::-ms-expand {
                    display: none;
                }

                &:focus {
                    background-color: $gray-100;
                    border-bottom: 1px solid $gray-800;
                }
            }

            input {
                width: 100%;
                min-height: 40px;
                height: 7.75vh;
                max-height: 100px;
                border: none;
                border-bottom: 1px solid $gray-500;
                border-radius: $border-radius-sm;
                background-color: $gray-200;
                font-size: $font-size-xl;
                text-indent: 90px;
                outline-color: $gray-500;


                @include media-breakpoint-down(xl) {
                    font-size: $font-size-lg;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $font-size-lg;
                }

                &::placeholder {
                    text-indent: 100px;
                    font-size: $font-size-xl;
                    line-height: 100%;
                    text-transform: uppercase;
                    color: $gray-500;


                    @include media-breakpoint-down(xl) {
                        font-size: $font-size-lg;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: $font-size-lg;
                    }
                }

                &:focus {
                    background-color: $gray-100;
                    border-bottom: 1px solid $gray-800;
                }
            }

            label.over-container {
                display: block;
                position: absolute;
                z-index: 3;
                top: 0;
                left: 0;
                width: 100%;
                min-height: 40px;
                height: 7.75vh;
                max-height: 100px;
                background-color: $gray-200;
                border-bottom: 1px solid $gray-500;
                text-indent: 100px;
                font-size: $font-size-xl;
                line-height: 7.75vh;
                color: $gray-600;
                cursor: pointer;
                font-size: $font-size-xl;

                @include media-breakpoint-down(xl) {
                    font-size: $font-size-lg;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $font-size-lg;
                }

                &.loaded {
                    color: $black;
                }
            }
            /* date-picker style */
            .react-datepicker-wrapper {
                width: 100%;
            }

            .react-datepicker-popper {
                z-index: 5;
            }

            .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
                background-color: $success;

                &:hover {
                    background-color: $success;
                }
            }
        }

        .regw__form {
            &__check {
                display: flex;
                position: relative;
                z-index: 2;
                width: 100%;
                min-height: 50px;
                line-height: 1em;
                font-size: 16px;
                padding-left: 0px;
                z-index: 5;

                .form-check {
                    width: auto;
                    display: inline-block;
                    padding: 0px;
                    order: 3;

                    &--check {
                        order: 1;
                    }

                    img {
                        position: absolute;
                        left: 10px;
                        width: auto;
                        min-height: 10px;
                        height: 20px;
                        padding-right: 10px;
                    }

                    .form-check-label {
                        display: inline-block;
                        position: relative;
                        padding: 10px 15px 5px 45px;
                        background-color: white;
                        border-bottom: 1px solid transparent;
                        border-radius: $border-radius-sm;
                        opacity: 0.5;
                        cursor: pointer;
                        width: 100%;
                        min-height: 40px;
                        text-align: left;
                    }

                    input {
                        opacity: 0;
                        position: absolute;

                        &:checked + label.form-check-label {
                            background-color: $black;
                            border-color: $light;
                            color: $light;
                            opacity: 1;

                            &:before {
                                content: '';
                                display: block;
                                width: 10px;
                                height: 10px;
                                transform: rotate(45deg);
                                position: absolute;
                                bottom: -4px;
                                left: 20px;
                                border-radius: inherit;
                                background-color: inherit;
                                ;
                            }

                            img {
                                filter: brightness(0) invert(1);
                            }
                        }
                    }
                }
            }
        }

        &--stores {
            position: relative;
        }
    }

    &__proceed {
        .btn {
            text-transform: uppercase;
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
            padding-left: $padding-xl;
            padding-right: $padding-xl;
        }
    }
}
    /* --- */
    .regu__form {
        &__group {
            width: 100%;
            position: relative;
            margin-bottom: $padding-sm;

            label {
                font-size: $font-size-sm;
                font-weight: $font-weight-bold;
                position: absolute;
                top: $padding-xxxs;
                left: $padding-xs;
            }

            input {
                width: 100%;
                padding: $padding-xl $padding-xs $padding-xs $padding-xs;
                outline-color: $gray-600;
                outline-width: 1px;
                border: none;
                border-bottom: 1px solid $gray-600;
                border-radius: $border-radius-sm;

                &:focus + label {
                    opacity: $btn-disabled-opacity;
                }
            }
        }
    }
    /*force loading spin */
    img[src$="spinner-third-light.svg"] {
        -webkit-animation: spin 300ms linear infinite;
        animation: spin 300ms linear infinite;
    }
    /* --- 
    .loader {
        width: 100%;
        background-color: lighten($green, 50%);
        position: relative;
        border-radius: $border-radius-sm;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(lg) {
            width: 90%;
        }

        @include media-breakpoint-down(md) {
            width: 80%;
        }

        > span {
            display: block;
            position: relative;
            width: 1%;
            height: 10px;
            background-color: $green;
            border-radius: $border-radius-sm;
            transition: width ease-in-out 300ms;

            > span {
                position: absolute;
                top: 20px;
                min-width: 90px;
                left: 100%;
                transform: translateX(-50%);
                background-color: $green;
                border-radius: $border-radius-sm;
                color: $white;
                font-size: $font-size-xs;
                text-transform: uppercase;

                > i {
                    position: absolute;
                    top: -3px;
                    left: 50%;
                    margin-left: -5px;
                    display: block;
                    width: 8px;
                    height: 8px;
                    background-color: $green;
                    z-index: -1;
                    transform: rotate(45deg);
                }
            }
        }
    }
    */