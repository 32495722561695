@import "css/production.variables.scss";

@import "bootstrap/scss/_functions.scss";
@import "../main/bootstrap_variables.scss";
@import "bootstrap/scss/_mixins.scss";
.accountpanel {
    display: block;
    width: 100%;
    background-image: url(#{$public-path}/images/home.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @include media-breakpoint-down(md) {
        height: auto;
        min-height: 0px;
        background: noen;
        padding: 0px;
    }
}
.accountpanel > .bg-white {
    max-width: 520px;
    min-height: 75vh;
    margin-left: auto;
    padding: 0px 15px 10px 15px;

    @include media-breakpoint-down(lg) {
        padding: 8px 30px 30px 30px;
    }

    @include media-breakpoint-down(md) {
        max-width: 100%;
            padding: 0px 20px 20px 20px;
    }
}

.accountButton {
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: white;
    background-repeat: no-repeat;
    transition: background-position ease-in-out 100ms;
    padding: 8px 10px 8px 30px;
    background-position: 8px center;
    background-size: 21px;
    font-size: 1em;

    @include media-breakpoint-down(md) {
        font-size: 0.8em;
    }
}

.account-fb {
    background-image: url(#{$public-path}/images/icons/facebook_logo.svg);
}

.account-google {
    background-image: url(#{$public-path}/images/icons/google_logo.svg);
}
