@import "css/production.variables.scss";

@import "bootstrap/scss/_functions.scss";
@import "../main/bootstrap_variables.scss";
@import "bootstrap/scss/_mixins.scss";
.homepage{
    display: block;
    height: calc(100vh - 115px);
    min-height: 770px;
    width: 100%;
    background-image: url(#{$public-path}/images/home.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include media-breakpoint-down(lg) {
        min-height: 900px;
    }    
    @include media-breakpoint-down(md) {
        height: auto;
        min-height: 770px;
        background-size: cover;
        background-position: top center;
    }
 
}