.vitec-brands {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0px;
  padding: 0px 0px 0px 15px;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.vitec-brands__item {
  margin-right: 18px;

  ul.head-brands {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      float: left;

      a {
        display: block;
        width: 65.29px;
        height: 40px;
        margin-top: 0px;
        padding-right: 0;
        margin-right: $padding-xs;
        overflow: hidden;
        text-indent: -999px;
        vertical-align: middle;
        opacity: 0.35;
        transition: opacity ease-in-out 200ms;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
}

.sponsors-menu__item {
  font-size: 13px;
  text-transform: uppercase;

  a {
    color: $primary;

    &:hover {
      text-decoration: none;
    }
  }


}

/* Group Brands  */

.group-brands {
  background: $gray-300;

  @include media-breakpoint-down(md) {
    display: none;
  }

  &__title {
    width: 340px;
    min-width: 291px;
    padding: 32px 41px 0;
    border-left: 1px solid #eaeaea;

    p {
      font-size: $font-size-xsm;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      color: $gray-600;

      @include media-breakpoint-down(lg) {
        font-size: $font-size-sm;
      }
    }
  }

  &__items {
    padding-left: 20px;
    width: 100%;
    max-width: 1400px;

    @include media-breakpoint-down(xlg) {
      max-width: 900px;
    }

    ul {
      width: 100%;
      margin: 0px;
      padding: $padding-xs;
      list-style-type: none;

      li {
        float: left;

        span {
          display: none;
        }

        a {
          display: block;
          width: 90px;
          height: 56.25px;
          margin-top: 0px;
          padding-right: 0;
          margin-right: $padding-lg;
          overflow: hidden;
          text-indent: -9999px;
          vertical-align: middle;
          opacity: 0.50;
          transition: opacity ease-in-out 200ms;

          @include media-breakpoint-down(xl) {
            width: 75px;
            height: 46.875px;
            margin-right: $padding-sm;
          }

          @include media-breakpoint-down(xlg) {
            width: 65px;
            height: 40.675px;
            margin-right: $padding-xs;
          }

          &:hover {
            opacity: 0.75;
          }

          span {
            display: none;
          }
        }

        &.custom-brand {
          a {
            text-indent: initial;

            img {
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }

    }
  }

}