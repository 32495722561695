.main-nav {
  background-color: $white;
  position: relative;
  z-index: 9;
  border-bottom: 1px solid $gray-300;
  .logo {
    min-width: 84px !important;
    width: 120px;
    margin: -6px 0px 0px 0px;
    transition: width ease-in-out 300ms, margin ease-in-out 300ms;
    a {
      max-width: 90px;
      display: block;
      overflow: hidden;
    }
  }
}

.main-menu__container {
  flex: 1;
}

.main-menu {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
  padding-inline-start: 0px;
  &:hover {
    .main-menu__item {
      opacity: 0.75;
      transition: opacity ease-in-out 200ms, height 200ms linear 0ms;
    }
  }
}

.main-menu__item {
  padding-right: 32px;
  color: $black;
  text-transform: uppercase;
  font-weight: 700;
  list-style: none;
  >a{
    font-size: $font-size-sm;
  }
  >span {
    cursor: pointer;
    font-size: $font-size-sm;
  }
  &--light {
    color: $black;
  }
  i {
    top: 2px;
    position: relative;
    color: $gray-300;
    font-size: $font-size-lg;
    transition: transform ease-in-out 100ms, color ease-in-out 100ms;
  }
  &.text-red{
    > a{
      color: $red;
    }
    i{
      display: none;
    }
  }
  @include media-breakpoint-up(md) {
    &:hover {
      opacity: 1 !important;
      i {
        transform: rotate(180deg);
        color: $primary; 
      }


    }
  }
  &.active {
    opacity: 1 !important;
    &:after{
      content: '';
      display: block;
      position: fixed;
      top: 110px;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      z-index: 5;
      pointer-events: none;
      background-color: rgba($white, 0.35);
    }
    i {
      transform: rotate(180deg);
      color: $primary;
    }
    .submenu-container {
      opacity: 1;
      height: auto;
      visibility: visible;
      pointer-events: auto;
    }
  }
  .frame-layout-1 ul li a{
    font-weight: $font-weight-normal;
    text-transform: none;
  }
}

.main-menu__text {
  display: inline-block;
  padding: 23px 0 23px 8px;
  text-decoration: none !important;
  transition: padding ease-in-out 200ms;

  &, &:hover, &:focus, &:active {
    color: inherit;
    text-decoration: none;
  }
}
.is_stuck .main-menu__text {
	padding: 8px 0 8px 8px;
}
@include media-breakpoint-up(md) {
  a.main-menu__text{
    color: $black;
  }
}
@include media-breakpoint-down(lg) {
  .main-menu__text{
     font-size: $font-size-xs;
   }
 }

.secondary-country {
  display: none;
}

/* /////////// responsive overrride grouped /////////// */

/* ----------------------------------------------------------------------------------------------------------- */

@include media-breakpoint-up(lg) {
  .main-nav.is_stuck .logo {
    width: 130px;
    margin-right: 20px;
  }
}

/* ----------------------------------------------------------------------------------------------------------- */

@include media-breakpoint-down(lg) {
  .main-nav .logo {
    width: 130px;
    margin-right: auto;
    padding-top: 8px;
    min-height: 70px;
  }
  .main-menu__item {
    font-size: 13px;
    padding-right: 16px;
  }
}

/* ----------------------------------------------------------------------------------------------------------- */

@include media-breakpoint-down(md) {
  body {
    padding-top: 65px;
  }

  .main-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    .logo {
      width: 110px;
      padding-top: 15px;
      min-width: 100px !important;
    }
    .main-menu__text {
      display: inline-block;
      padding: 18px 0 16px 8px;
    }
  }
  .main-menu__item {
    &.active {
      .submenu-container {
        padding-bottom: 20px;
        &:before {
          content: '';
          display: block;
          height: 1px;
          width: auto;
          background-color: $gray-200;
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
  }
  .main-menu__text {
    padding: 12px 0 12px 8px;
    width: 100%;
    color: $black;
  } // hide menu
  .main-menu {
    visibility: hidden;
    position: absolute;
    z-index: 8;
    opacity: 0;
    height: 0px;
    width: 100%;
    transition: opacity ease-in-out 200ms, height ease-in-out 200ms;
    &__back{
      border: none;
      background-color: $gray-200;
      color: $black;
      box-shadow: none;
      width: 100%;
      padding: 0;
      margin: 0;
      height: auto;
      line-height: 24px;
    }
  } // open menu
  .main-menu__container.active .main-menu {
    visibility: visible;
    opacity: 1;
    display: block;
    top: 100px;
    left: 0px;
    margin: 0px;
    padding: 0px 0px 20px 0px;
    background-color: $white;
    min-height: 100vh;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
  } // reset links style
  .main-menu__item {
    color: $black;
    font-size: $font-size-lg;
    line-height: 1em;
    padding: 0px 50px 0px 28px;
    border-left: 2px solid transparent;
    border-bottom: 1px solid $gray-200;
    i {
      position: absolute;
      right: 30px;
      margin-top: 10px;
      color: transparent;
    } // add style for active
    &.active {
      border-left: 2px solid $primary; //@include box_shadow(8);
      >.main-menu__text {
        color: $primary;
      }
    }
  }
  .main-menu__container {
    display: inline-block;
    width: auto;
    margin-right: -4px;
    float: none;
  } // set hamburger button
  .open-main-menu-mobile {
    background: transparent;
    display: block;
    padding: 0;
    margin: 0 0 0 auto;
    width: 18vw;
    text-align: center;
    box-shadow: none;
    i {
      font-size: $font-size-lg;
      font-weight: 400;
      font-family: 'Font Awesome 5 Pro';
      color: $black;
    }
    small {
      display: block;
      font-size: $font-size-xs;
      opacity: 0.75;
      color: $black;
    }
  } // set hamburger menu open
  .active {
    .open-main-menu-mobile {
      i:before {
        content: "\f00d";
        color: $primary;
        font-weight: 400;
      }
    }
  }
  /* 
    style the country nav (dplicate to inserti it in the main nav)
    could not edit current html to moove with only css inside the menu... 
    is reasonable to copy paste country select html with js inside nav when mobile?
    */
  .secondary-country {
    display: block;
    position: static;
    height: 40px;
    margin-bottom: 5px;
    background-color: $gray-200;
    >h4 {
      position: static;
      display: inline-block;
      line-height: 40px;
      margin: 0;
      padding: 0 0 0 40px;
      z-index: 2;
      font-size: $font-size-lg;
    }
    .country-selector__single {
      display: inline-block;
      .country-selector__country a {
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
      }
    }
    .menu-country {
      position: relative;
      z-index: 0;
      ul {
        position: relative;
        background-color: $gray-200 !important;
        z-index: 0;
      }
    }
  }
}
.actions-menu svg{
  min-width: 16px;
}
/* ----------------------------------------------------------------------------------------------------------- */

@include media-breakpoint-down(xl) {
  .main-menu__item {
    padding-right: 24px;
  }
}

@media (max-width : 1300px) {
  .main-menu > .main-menu__item > .main-menu__text{
    font-size: 13px!important;
  }
  .main-menu > .main-menu__item {
    padding-right: 20px;
  }
}

body .main-menu__special-offers{
  color: $danger;
}
