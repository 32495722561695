.loader {
    width: 100%;
    background-color: lighten($green, 50%);
    position: relative;
    border-radius: $border-radius-sm;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(lg) {
        width: 90%;
    }
    @include media-breakpoint-down(md) {
        width: 80%;
    }

    >span {
        display: block;
        position: relative;
        width: 1%;
        height: 10px;
        background-color: $green;
        border-radius: $border-radius-sm;
        >span {
            position: absolute;
            z-index: 2;
            top: 20px;
            min-width: 90px;
            left: 100%;
            transform: translateX(-50%);
            background-color: $green;
            border-radius: $border-radius-sm;
            color: $white;
            font-size: $font-size-xs;
            text-transform: uppercase;
            >i {
                position: absolute;
                top: -3px;
                left: 50%;
                margin-left: -5px;
                display: block;
                width: 8px;
                height: 8px;
                background-color: $green;
                z-index: -1;
                transform: rotate(45deg);
            }
        }
    }
}