.react-autosuggest__container {
    position: relative;
    z-index: 3;
}

/* hide code, already styled
  .react-autosuggest__input {
    width: 100%;
    max-width: 620px;
    height: 30px;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
    -webkit-appearance: none;
  }
  */

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input::-ms-clear {
    display: none;
}

/*
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  */
.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    overflow: auto;
    top: 100%;
    width: 100%;
    max-width: 620px;
    max-height: 20vh;
    border: 1px solid $gray-500;
    background-color: #fff;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 4;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
/*
.react-autosuggest__suggestion--first{
    background-color: $dark;
    color: $white;
    font-weight: $font-weight-bold;
    &:before{
        content: '\2192';
        display: inline-block;
        width: 20px;
        height: 20px;
    }
}
*/
.react-autosuggest__no-suggestion{
    position: absolute;

    display: inline-block;
    width: 100%;
    padding: 5px 10px;
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
    
    background-color: $success;
    color: $white;
    text-transform: uppercase;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
}