div.sub-nav {
    text-align: center;
}

div.sub-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

div.sub-nav ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 13.8%;
}

div.sub-nav ul li a {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid #eeeeee;
}

div.sub-nav ul li:last-child a {
    border: none;
}

div.sub-nav ul li a img {
    display: block;
    margin: 0 auto;
    position: relative;
}

div.sub-nav ul li a:hover img {
    opacity: 0.8;
}
